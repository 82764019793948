var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Việt test")]),
      _c("spinner", { attrs: { message: _vm.$tableLoading } }),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.reloadPage()
            },
          },
        },
        [_vm._v("OK")]
      ),
      _vm._v("\n  " + _vm._s(_vm.listTest) + "\n\n  "),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.testMethod()
            },
          },
        },
        [_vm._v("Click")]
      ),
      _c("div", [_vm._v(_vm._s(_vm.lastList))]),
      _c("hr"),
      _c(
        "div",
        {
          ref: "html2Pdf",
          attrs: {
            "show-layout": false,
            "float-layout": true,
            "enable-download": false,
            "preview-modal": false,
            filename: "hehehe",
            "paginate-elements-by-height": 1100,
            "pdf-quality": 2,
            "pdf-format": "a4",
            "pdf-orientation": "portrait",
            "pdf-content-width": "800px",
            "manual-pagination": false,
          },
          on: {
            progress: function ($event) {
              return _vm.onProgress($event)
            },
            startPagination: function ($event) {
              return _vm.startPagination()
            },
            hasPaginated: function ($event) {
              return _vm.hasPaginated()
            },
            beforeDownload: function ($event) {
              return _vm.beforeDownload($event)
            },
            hasDownloaded: function ($event) {
              return _vm.hasDownloaded($event)
            },
          },
        },
        [
          _c(
            "section",
            { attrs: { slot: "pdf-content" }, slot: "pdf-content" },
            [_c("hhhh", { attrs: { slot: "" }, slot: "default" })],
            1
          ),
        ]
      ),
      _c(
        "div",
        { ref: "document", staticStyle: { color: "red", "font-size": "30px" } },
        [_vm._v("this is document")]
      ),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.exportToPDF()
            },
          },
        },
        [_vm._v("Export to PDF")]
      ),
      _c("hr"),
      _c(
        "el-button",
        {
          attrs: { type: "success" },
          on: {
            click: function ($event) {
              return _vm.AddP()
            },
          },
        },
        [_vm._v(" Add propertiest")]
      ),
      _vm._v("\n  " + _vm._s(_vm.objectTest) + "\n  "),
      _c("hr"),
      _vm._v("\n  " + _vm._s(_vm.myVar) + "\n  "),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.changeText()
            },
          },
        },
        [_vm._v("Chuyen doi")]
      ),
      _vm._v("\n  " + _vm._s(_vm.myVar1) + "\n  "),
      _c("hr"),
      _c("upload-excel", {
        attrs: {
          "on-success": _vm.handleSuccess,
          "before-upload": _vm.beforeUpload,
        },
      }),
      _c("br"),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            "highlight-current-row": "",
            "header-cell-style": _vm.tableHeaderColor,
          },
        },
        _vm._l(_vm.tableHeader, function (item) {
          return _c("el-table-column", {
            key: item,
            attrs: { prop: item, label: item },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }